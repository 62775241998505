<template>
  <div class="page">
    <v-refresh class="vrefresh" v-model="isLoading" @refresh="onRefresh">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="replyDetail"
          v-for="(item, index) in [detail, ...replyList]"
          :key="index"
          @click="toReply(item, index)"
        >
          <div class="userPhoto">
            <img
              :src="
                item.avatar || item.fromUserAvatar
                  ? item.avatar || item.fromUserAvatar
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="userInfo">
            <div class="info-title">
              <div class="title-name">
                {{ index === 0 ? item.userName : item.fromUserName }}
              </div>
              <div class="title-wrap">
                <div class="iconText" v-if="index === 0" @click="toReply(null)">
                  <div class="icon">
                    <img src="./img/icon-msg.png" />
                  </div>
                </div>
                <div class="iconText" @click.stop="toClickLike(item, index)">
                  <div class="icon icon-up">
                    <img v-if="!item.hasPraised" src="./img/icon-like.png" />
                    <img v-else src="./img/icon-like-red.png" alt="" />
                  </div>
                  <div class="text">
                    <span :class="{ hasPraised: item.hasPraised }">
                      {{ item.praiseCount }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="info-foot">
              <div class="foot-time">{{ item.createTime }}</div>
            </div>
            <div class="info-content">
              <span class="info-content-reply" v-if="item.toUserName">
                回复 @{{ item.toUserName }}：
              </span>
              {{ index === 0 ? item.commentContent : item.replyContent }}
            </div>
            <div class="info-pic" v-if="item.commentPic">
              <pic-list :pic-list="item.commentPic.split(',')"></pic-list>
            </div>
          </div>
        </div>
      </v-list>
    </v-refresh>
    <footer @click="toReply(null)">
      <div class="footer-wrap">
        <div class="input">请输入</div>
      </div>
    </footer>
    <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend"
    ></v-comments>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getTopicReplyList,
  addCommentURL,
  cancelPraiseURL,
  addPraiseURL,
} from "./api";
import picList from "@/components/bussiness/picList";
import { vComments } from "@/components/control/index";

export default {
  name: "topicReplyDetail",
  components: {
    vComments,
    picList,
  },
  data() {
    return {
      finished: false,
      isLoading: false,
      finishedText: "没有更多了",
      curPage: 1,
      pageSize: 20,
      text: "",
      detail: {},
      curReply: null,
      replyList: [],
      isShowComments: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const detailStr = this.$route.query.detail;
    this.detail = detailStr ? JSON.parse(detailStr) : {};
    console.log(this.detail);
  },
  methods: {
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicReplyList, {
          params: {
            commentId: this.detail.commentId,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          if (res.data.records.length === 0) {
            this.replyList = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            this.commentsData = res.data;
            if (this.curPage === 1) {
              this.replyList = res.data.records;
            } else {
              const list = this.replyList.map((v) => v.replyId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.replyId) || this.replyList.push(item);
              });
            }
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 点赞和取消点赞
    toClickLike(data, index) {
      const { closePraise } = this.detail;
      // 0 否 1 是
      if (closePraise === 1 || closePraise === "1") {
        this.$toast({
          message: "点赞功能已关闭",
          duration: 1500,
        });
        return;
      }
      // index === 0 则是评论点赞，反之则是回复点赞
      const item = index === 0 ? this.detail : data;
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: index === 0 ? item.commentId : item.replyId, // commentId 评论主键id replyId 回复id
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            if (item.hasPraised) {
              // 取消点赞
              item.hasPraised = false;
              item.praiseCount -= 1;
            } else {
              // 点赞
              item.hasPraised = true;
              item.praiseCount += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    // 点击回复跳出回复控件
    toReply(item, index) {
      if (index === 0) {
        return;
      }
      const { closeComment } = this.detail;
      // 0 否 1 是
      if (closeComment === 1 || closeComment === "1") {
        this.$toast({
          message: "评论功能已关闭",
          duration: 1500,
        });
        return;
      }
      this.curReply = item;
      this.isShowComments = true;
      // 获取焦点
      setTimeout(() => {
        this.$refs.vComments.getFocus();
      }, 500);
    },
    // 接受评论组件发送过来的信息
    acceptSend(val) {
      if (!(val.trim && val.trim())) {
        this.$toast({ message: "请输入内容", duration: 1500 });
        return;
      }
      const item = this.curReply;
      let params = {};
      if (item) {
        // 如果存在则为回复的回复
        params = {
          topicId: item.topicId, // 话题id
          commentId: item.commentId, // 评论id
          replyId: item.replyId,
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 2, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: item.fromUserId, // 被回复人ID
        };
      } else {
        // 反之则为评论的回复
        params = {
          topicId: this.detail.topicId, // 话题id
          commentId: this.detail.commentId, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 1, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
        };
      }
      // 1. 调接口
      this.$toast.loading({
        duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          ...params,
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
          skipImageScan: "skip",
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            // 4. 调评论接口
            this.onRefresh();
            // item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 96px;
  background: #f7f7f7;
  .vrefresh {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .replyDetail {
      display: flex;
      // margin-top: 32px;
      padding: 32px;
      background: #ffffff;
      &:not(:first-child) {
        background: #f7f7f7;
        .userInfo {
          .info-content {
            font-size: 28px;
          }
        }
      }
      .userPhoto {
        height: 72px;
        width: 72px;
        min-width: 72px;
        overflow: hidden;
        border-radius: 50%;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .userInfo {
        word-wrap: break-word;
        flex: 1;
        margin-left: 16px;
        max-width: calc(100% - 88px);
        .info-title {
          display: flex;
          .title-name {
            box-sizing: border-box;
            width: 100%;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title-wrap {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            > .iconText {
              display: flex;
              // margin-right: 32px;
              color: #a8a8a8;
              // align-items: center;
              &:not(:last-child) {
                margin-right: 32px;
              }
              .icon {
                display: flex;
                align-items: center;
                // width: 32px;
                // height: 32px;
                overflow: hidden;
                &.icon-up {
                  > img {
                    margin-top: -2px;
                  }
                }
                > img {
                  width: 32px;
                  height: 32px;
                }
              }
              .text {
                margin-left: 4px;
                display: flex;
                align-items: center;
                height: 32px;
                span {
                  &.hasPraised {
                    color: #ff853a;
                  }
                  // color: #ff853a;
                  font-size: 24px;
                  // line-height: 1;
                }
              }
            }
          }
        }
        .info-foot {
          margin: 8px 0 16px 0;
          line-height: 32px;
          display: flex;
          .foot-time {
            color: #a8a8a8;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a8a8a8;
          }
        }
        .info-content {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 44px;
          .info-content-reply {
            color: #3c6e9e;
          }
        }
        .info-pic {
          margin-top: 16px;
        }
      }
    }
  }

  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .footer-wrap {
      padding: 16px 32px;
      .input {
        border-radius: 32px;
        // width: 100%;
        color: #cccccc;
        background: #f5f5f5;
        padding: 10px 32px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        line-height: 44px;
      }
    }
  }
}
</style>
